<template>
  <div class="refunds-helper">
    <div class="notification-wrapper">
      <div v-for="(notification,index) in notifications" :key="notification" class="notification" :data-index="index" :class="notification.class">
        <div v-if="notification.header" class="notification-header">
          <p v-html="notification.header"></p>
        </div>
        <div class="notification-body">
          <p v-html="notification.body"></p>
        </div>
      </div>
    </div>
  
    <div v-if="!allowed" id="password">
     <div class="container">
       <div class="row">
         <div>
            <h3>Voer wachtwoord in</h3>
            <div class="input-group">
              <form @submit.prevent="testPassword()">
                <div class="input-group">
                  <button type="submit" class="btn btn-outline-primary">Login</button>
                  <input type="password" v-model="password" class="form-control">
                </div>
              </form>
            </div>
         </div>
       </div>
     </div>
    </div>
    <div v-if="allowed" id="protected">
       
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <h3>Vul hier je ordernummer in</h3>
            <form @submit.prevent="get_order(incrementid)" action="">
              <div class="input-group">
                <button class="btn btn-primary">
                  Zoek op🔎
                </button>
                <input type="text" v-model="incrementid" class="form-control" />
            </div>
            </form>
            <div class="form-check form-switch">
              <h4>Klantenservice?</h4>
              <input class="form-check-input" type="checkbox" v-model="customerservice" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Hulptekst</label>
            </div>
            <div class="examples">
              <h4>Voorbeelden</h4>
              <button class="btn btn-outline-secondary example" @click.self="clicktocopy(example)" v-for="example in examples" :key="example" type="text"> {{example}} </button>
            </div>
        </div>
        <div class="col-10">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Refunded?</th>
                <th scope="col">Product sku</th>
                <th scope="col">product Name</th>
                <th scope="col">Total</th>
                <th scope="col">Discount</th>
                <th scope="col">Invoiced</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order_items" :key="item">
                <td>
                  <input
                    type="checkbox"
                    :id="'item_id'+item.item_id"
                    @change="calculate()"
                    class="form-check"
                    :value="item"
                    v-model="selected"
                  />
                </td>
                <td scope="col">{{ item.sku }}</td>
                <td scope="col">{{ item.name }}</td>
                <td scope="col">{{ roundnumber(item.base_row_total_incl_tax + item.base_discount_invoiced) }}</td>
                <td scope="col">{{ roundnumber(item.base_discount_invoiced) }}</td>
                <td scope="col">{{ roundnumber(item.base_row_total_incl_tax) }}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div class="row" v-if="customerservice && calculation.total>0">
           <div @click="clicktocopy($event.target.innerText)" class="form-control" readonly>
            Het teruggestorte bedrag van €{{calculation.to_refund}} is als volgt berekend:
            De totaal prijs van alle artikelen in je bestelling was €{{calculation.total}} zonder korting. Hiermee had je recht op {{ roundnumber( (calculation.invoiced_discounts / calculation.total) ) * 100}}% korting, waardoor je in totaal €{{calculation.invoiced_discounts}} korting hebt ontvangen en uiteindelijk €{{calculation.total - calculation.invoiced_discounts}} hebt betaald.
            Door het terugsturen van een of meerdere artikelen heb je niet meer recht op dezelfde korting. Je totaal prijs van alle behouden artikelen komt nu uit op €{{ roundnumber(calculation.total - (calculation.amount_refunded + calculation.returned_discounts)) }}, waardoor je nu nog maar recht hebt op {{ roundnumber( (calculation.allowed_discount/(calculation.new_order_amount + calculation.allowed_discount)) ) * 100 }}% korting.
            Wanneer je de nieuwe korting toepast op je behouden artikelen, kom je uit op een korting van €{{calculation.allowed_discount}}.
            Daarnaast worden er nog retourkosten van €{{calculation.refund_costs}} ingehouden.
            De som van je behouden artikelen - €{{calculation.allowed_discount}} korting - €{{calculation.refund_costs}} retourkosten, is dan €{{calculation.to_refund}}.
           </div>
          </div>
          <div class="row justify-content-end result">
            <div class="col-6">
              <div v-if="Object.keys(discount_details).length > 0">
                <table class="table">
                <tbody>
                  <tr>
                    <td>Naam</td>
                    <td>{{discount_details.name}}</td>
                  </tr>
                  <tr>
                    <td>Applied Discount Code</td>
                    <td>{{discount_code}}</td>
                  </tr>
                  <tr>
                    <td>Korting</td>
                    <td>{{discount_details.discount_amount}}%</td>
                  </tr>
                  <tr>
                    <td>Omschrijving</td>
                    <td>{{discount_details.description}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table">
                <thead>
                  <tr>
                    <th>Attribuut</th>
                    <th>Operator</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="condition in discount_details.action_condition.conditions" :key="condition">
                    <td>{{condition.attribute_name}}</td>
                    <td>{{condition.operator}}</td>
                    <td>{{condition.value}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div class="col-5">
              <table class="table">
              <tbody>
                <tr>
                  <td>Totaal Geretouneerd</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.amount_refunded"></td>
                </tr>
                <tr>
                  <td>Invoiced discount</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" :value="roundnumber(calculation.invoiced_discounts - calculation.returned_discounts)"></td>
                </tr>
                <tr>
                  <td>Allowed discount</td>
                  <td><input @change="calculate(calc_discount = false)" type="text" class="form-control" v-model="calculation.allowed_discount"></td>
                </tr>
                <tr>
                  <td>Too much discount</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.discount_difference"></td>
                </tr>
                <tr>
                  <td>Shipping Costs</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.shipping_costs"></td>
                </tr>
                <tr>
                  <td>Totaal Over</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.new_order_amount"></td>
                </tr>
                <tr>
                  <td>Retourkosten</td>
                  <td><input @keydown="calculate()" type="text" pattern="\d*" class="form-control" v-model="calculation.refund_costs"></td>
                </tr>
                <tr>
                  <td>Bedrag na retourkosten</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.result"></td>
                </tr>
                <tr class="torefund">
                  <td>To Refund</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.to_refund"></td>
                </tr>
                 <tr>
                  <td>Adjustment fee</td>
                  <td><input readonly type="text" pattern="\d*" class="form-control" v-model="calculation.adjustmentfee"></td>
                </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<style lang="scss">
.container-fluid {
  padding: 0px 100px 0px 30px;
}
.result {
  text-align: right;
  table {
    input{
      text-align: right;
    }
  }
  table
  .torefund{
    border-top: double;
  }
  td:first-child {
    font-weight: bold;
  }
}
.examples .example{
  width: 100%
}
.notification-wrapper{
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .notification {
    margin-right: 20px;
    margin-bottom: 20px;
    background: rgba(249, 251, 255, 0.945);
    padding: 10px 40px;
    border-radius: 5px;
    width: 300px;
    border: #015db1 solid thin;
    animation: notification-in .4s;
    box-shadow: 0px 0px 30px -19px rgb(0 0 0 / 95%);
    strong{
      color: #015db1
    }
    &.fadeout{
      animation: notification-out .4s;
      transform: translate3d(120%,0,0);
    }
  }    
}
@keyframes notification-in {
  0% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes notification-out {
  0% {
    opacity: 1;
    transform: none;
  }

  100% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
}
</style>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      allowed: false,
      password: '',
      temphash: '',
      customerservice: false,
      examples: [
        1000240036,
        1000243623,
        3000018758,
        27000017619,
      ],
      order_details: {},
      order_items_raw: [],
      order_items: [],
      selected: [],
      incrementid: '1000240036',
      calculation: {
        amount_refunded: 0,
        refund_costs: 2,
        result: 0,
        total: 0,
        new_order_amount: 0,
        allowed_discount: 0,
        invoiced_discounts: 0,
        returned_discounts: 0,
        discount_difference: 0,
        to_refund: 0,
        shipping_costs: 0
      },
      discount_details: {},
      discount_code: '',
      notifications: []
    };
  },
  async mounted() {
    this.on_mounted()
  },
  methods: {
    testPassword(){
      const self = this
      axios({
        method: "POST",
        url: "https://api.swsreports.app/magento/auth",
        data: {
          password: this.password
        }
      }).then(function(req){
          console.log(req)
          self.allowed = req.data.allowed
          self.temphash = req.data.temphash
          if(self.allowed === false){
            self.add_notification({
              body: "Wachtwoord is niet goed"
            })
          }
      })
      
    },
    resetNumber(){
      const self = this; 
      self.calculation.total = 0
      self.calculation.result = 0
      self.calculation.new_order_amount = 0
      self.calculation.amount_refunded = 0
      self.calculation.invoiced_discounts = 0
      self.calculation.returned_discounts = 0
      self.calculation.discount_difference = 0
      self.calculation.to_refund = 0
      self.calculation.adjustmentfee = 0
      self.discount_details = {}
      self.selected = []
    },
    on_mounted() {
      const self = this
      this.resetNumber()

      self.order_items = self.order_items_raw.filter(function (obj) {
        return obj.product_type === "configurable";
      });
      for (var i = 0; i < self.order_items.length; i++) {
        self.calculation.total += self.order_items[i].base_row_total_incl_tax + self.order_items[i].base_discount_invoiced
        self.calculation.invoiced_discounts += self.order_items[i].base_discount_invoiced
      }

      this.calculate()
      this.calculate_discount()
      this.roundnumber()


    },
    async calculate(calc_discount = true) {
      const self = this;
      self.calculation.result = 0
      self.calculation.amount_refunded = 0;
      self.calculation.returned_discounts = 0;

      for (var i = 0; i < self.selected.length; i++) {
        self.calculation.amount_refunded += self.selected[i].base_row_total_incl_tax
        self.calculation.returned_discounts += self.selected[i].base_discount_invoiced
      }

      if(calc_discount === true){
        self.calculate_discount()
      }
      this.calculate_new_order_amount()

      if (self.calculation.amount_refunded > 0) {
        self.calculation.result = self.calculation.amount_refunded - self.calculation.refund_costs
      }
      
      this.to_refund()
      this.roundnumber()
    },
    calculate_new_order_amount(){
      const self = this
      self.calculation.new_order_amount = self.calculation.total - (self.calculation.amount_refunded + self.calculation.returned_discounts) - self.calculation.allowed_discount
    },
    to_refund(){
      const self = this
      self.calculation.to_refund = self.calculation.result - self.calculation.discount_difference
      if(self.order_items.length === self.selected.length){
        self.calculation.to_refund += self.calculation.shipping_costs
      } else {
        self.calculation.new_order_amount += self.calculation.shipping_costs
      }
      self.calculation.adjustmentfee = self.calculation.refund_costs + self.calculation.discount_difference
    },
    roundnumber(number = false){
      if(number !== false){
        return (Math.round((parseFloat(number.toString().replace(/\,/g, '.'))) * 100) / 100).toFixed(2);
      }
      const numbers = this.calculation
      for(var number in numbers){
        numbers[number] = Math.round((parseFloat(numbers[number].toString().replace(/\,/g, '.'))) * 100) / 100
      }
    },
    calculate_discount() {
      const calculation = this.calculation;
      const order_amount = (calculation.total - calculation.returned_discounts - calculation.amount_refunded)


      if (order_amount > 500) {
        calculation.allowed_discount = order_amount * 0.15;
      } else if (order_amount > 250) {
        calculation.allowed_discount = order_amount * 0.1;
      } else if(order_amount > 100){
        calculation.allowed_discount = order_amount * 0.05;
      } else {
        calculation.allowed_discount = 0
      }

      calculation.discount_difference = (calculation.invoiced_discounts - calculation.returned_discounts) - calculation.allowed_discount

      this.roundnumber()
    },
    async get_order(increment_id) {
      const self = this
      this.resetNumber()

      await axios({
        method: "GET",
        headers: {
          'x-token': self.temphash
        },
        url: 'https://api.swsreports.app/magento/order/' + increment_id,
      }).then(function (req, res) {
          if(req.data.http_status === 403){
            window.location.reload()
          }
          console.log(req)
          self.order_items_raw = req.data.items[0].items;
          self.calculation.shipping_costs = req.data.items[0].base_shipping_incl_tax
          self.discount_code = req.data.items[0].discount_description
      });
      this.on_mounted();
      this.get_discountcode()
    },
    async get_discountcode(){
      const self = this
      await axios({
        method: "GET",
        headers : {
          'x-token': self.temphash
        },
        url: 'https://api.swsreports.app/magento/coupon/' + self.discount_code
      }).then(function(req){
        console.log(req)
        self.discount_details = req.data.items[0]
      })
    },
    clicktocopy(value){
      console.log(value)
      var $el = document.createElement('input')
      $el.value = value
      document.body.appendChild($el)
      $el.select()
      document.execCommand('copy')
      document.body.removeChild($el)

      this.add_notification({
        body: 'succesvol gekopieerd naar klembord'
      })
    },
    add_notification(not){
      const self = this
      var index = this.notifications.push(not) - 1

      if(('timeout' in not) === false ){
        var timeout = 3500
      } else if(not.timeout === false) {
        return false;
      } else {
        var timeout = not.timeout
      }

      setTimeout(() => {
        self.notifications[0].class = 'fadeout'
        setTimeout(() => {
          self.notifications.splice(0, 1)
        }, 500);
      }, timeout);
    }
  },
};
</script>
